
























































































































































































































import Vue from 'vue';
import { mapActions } from 'vuex';
import truncate from 'lodash.truncate';

import { ErrorManager, Notification, NotificationFieldError } from '@/models';

enum STEP {
  NOTIFICATION = 1,
  SEND = 2
}

interface Options extends InstanceType<typeof Vue> {
  $refs: {
    notificationForm: {
      reset(): void;
    } & (Vue | Element);
  };
}

export default Vue.extend<Options>({
  functional: false
}).extend({
  name: 'NotificationDetailForm',

  data: () => ({
    currentStep: STEP.NOTIFICATION,
    STEP,

    notification: new Notification(),
    fieldError: new NotificationFieldError(),
    errorMessage: '',

    textRules: [
      (v: string) => !!(v && v.trim()) || 'Field is required',
      (v: string) =>
        (v && v.length <= 256) || 'Field must be less than 256 characters'
    ],

    loading: false,

    androidImage: require('@/assets/images/notification/android.png'),
    iosImage: require('@/assets/images/notification/iphone.png')
  }),

  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    isStepNotificationError(): boolean {
      return (
        (this.fieldError.title.length || this.fieldError.message.length) > 0
      );
    }
  },

  methods: {
    truncate,

    ...mapActions('notification', {
      createNotification: 'createNotification'
    }),

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    clearTitleFieldError() {
      this.fieldError.title.splice(0, this.fieldError.title.length);
    },

    clearMessageFieldError() {
      this.fieldError.message.splice(0, this.fieldError.message.length);
    },

    async sendMessage() {
      this.loading = true;
      try {
        this.clearAllErrors();
        await this.createNotification(this.notification);
        this.$refs.notificationForm.reset();
        this.goToStep(STEP.NOTIFICATION);
      } catch (error) {
        if (error.response) {
          // client received an error response that falls out of range 2xx
          if (
            NotificationFieldError.isNotificationFieldError(error.response.data)
          ) {
            this.fieldError = new NotificationFieldError(error.response.data);
            this.goToStepFromFieldError();
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        }
      }
      this.loading = false;
    },

    goToStep(step: STEP) {
      this.currentStep = step;
    },

    goToStepFromFieldError() {
      if (this.isStepNotificationError) {
        this.goToStep(STEP.NOTIFICATION);
      } else {
        this.goToStep(STEP.SEND);
      }
    }
  }
});
