




import Vue from 'vue';

import NotificationDetailForm from '@/components/forms/NotificationDetailForm.vue';

export default Vue.extend({
  name: 'SendNotification',

  components: {
    NotificationDetailForm
  }
});
